/*----------------------------------------------------
@File: Default Styles
@Author: SPONDON IT

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: HostHub Construction 
@Developed By: Naim Lasker
Author E-mail: naimlasker@gmail.com

=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/
/*=================== fonts ====================*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500|Rubik:500,700");

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Medium Layout: 1280px */
/* Tablet Layout: 768px */
/* Mobile Layout: 320px */
/* Wide Mobile Layout: 480px */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
body {
  line-height: 26px;
  font-size: 15px;
  font-weight: 400;
  color: #777777;
  position: relative;
  
}
.loader{
 position: absolute;
 height: 100vh;
 width: 100%;
 background-color: white;
 z-index: 999999 !important;
 transition: 1s;
 display: flex;
 justify-content: center;
 align-items: center;

}
.hidden{
  overflow: hidden;
}
.loader img{
  height: 100px;
  width: 100px;
}
.loaderEnd{
  opacity: 0 !important;
  visibility: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #000000;
}

.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.gradient {
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%);
  -webkit-text-fill-color: transparent;
  color: #464646;
  display: inline-block;
  padding: 0;
  margin: 0;
}


a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.mt-25 {
  margin-top: 25px;
}

.p0 {
  padding-left: 0px;
  padding-right: 0px;
}

.white_bg {
  background: #fff !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-50 {
  margin-top: 50px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/



/* Main Title Area css
============================================================================================ */
.main_title {
  text-align: center;
  margin-bottom: 100px;
}

@media (max-width: 1199px) {
  .main_title {
    margin-bottom: 50px;
  }
}

.main_title h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 50px;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .main_title h2 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .main_title h2 {
    font-size: 30px;
    line-height: 34px;
  }
}

.main_title p {
  margin-bottom: 0px;
  font-size: 18px;
  max-width: 670px;
  width: 100%;
  margin: auto;
}

@media (max-width: 991px) {
  .main_title p br {
    display: none;
  }
}

.main_title.white h2 {
  color: #fff;
}

.main_title.white p {
  color: #fff;
  opacity: .6;
}

/* End Main Title Area css
============================================================================================ */
/* Start Gradient Area css
============================================================================================ */
.gradient-bg, .header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link, .blog_right_sidebar .widget_title, .blog_right_sidebar .newsletter_widget .bbtns, .blog_right_sidebar .tag_cloud_widget ul li a:hover, .blog-pagination .page-item.active .page-link, .blog-pagination .page-link:hover, .comments-area .btn-reply:hover {
  background: -webkit-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -moz-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -o-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
}

.border-gradient {
  border-image: -webkit-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  border-image: -moz-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  border-image: -o-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  border-image: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
}

.gradient-bg2 {
  background: -webkit-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -moz-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -o-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
}

.gradient-color, .contact_info .info_item i {
  background: -webkit-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -moz-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: -o-linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  background: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* End Gradient Area css
============================================================================================ */
.primary_btn {
  display: inline-block;
  color: #fff;
  letter-spacing: 0px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 46px;
  outline: none !important;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
  border: double 2px transparent;
  box-shadow: 0px 10px 30px rgba(118, 85, 225, 0.3);
}

.primary_btn span {
  padding: 0 42px;
}

.primary_btn.tr-bg {
  background-image: linear-gradient(#ffffff, #ffffff), radial-gradient(circle at top left, #4458dc, #854fee);
  border: 2px solid transparent;
  color: #222222;
  box-shadow: none;
}

.primary_btn.tr-bg:hover {
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
  border: double 2px transparent;
  color: #ffffff;
  box-shadow: 0px 10px 30px rgba(118, 85, 225, 0.3);
}

.primary_btn:hover {
  background-image: linear-gradient(#ffffff, #ffffff), radial-gradient(circle at top left, #4458dc, #854fee);
  border: 2px solid #854fee;
  color: #222222;
  box-shadow: none;
  background-clip: border-box;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.header_area {
  position: absolute;
  width: 100%;
  z-index: 9999;
  background: #ffffff;
}

.header_area .menu_nav {
  width: 100%;
}

@media screen and (min-width:992px) {
  .header_area .navbar {
    padding: 1px 0px !important;
  }
}

.header_area .navbar {
  background: transparent;
  padding: 0px 0px;
  border: 0px;
  border-radius: 0px;
  width: 100%;
}

.header_area .navbar .nav .nav-item {
  margin-right: 45px;
}

.header_area .navbar .nav .nav-item .nav-link {
  font: 500 14px/100px "Rubik", sans-serif;
  text-transform: uppercase;
  color: #000000;
  padding: 0px;
  display: inline-block;
}

.header_area .navbar .nav .nav-item .nav-link:after {
  display: none;
}

.header_area .navbar .nav .nav-item:hover .nav-link, .header_area .navbar .nav .nav-item.active .nav-link {
  color: #4458dc !important;
}

.header_area .navbar .nav .nav-item.submenu {
  position: relative;
}

.header_area .navbar .nav .nav-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}

@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu ul {
    position: absolute;
    top: 120%;
    left: 0px;
    min-width: 200px;
    text-align: left;
    opacity: 0;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 24px;
  top: 45px;
  z-index: 3;
  opacity: 0;
  transition: all 400ms linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 1px solid #ededed;
  margin-left: 0px;
  transition: all 0.4s linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  line-height: 45px;
  color: #000000;
  padding: 0px 30px;
  transition: all 150ms linear;
  display: block;
  text-transform: capitalize;
  margin-right: 0px;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
  border-bottom: none;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
  color: #fff;
}

@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
  margin-top: 0px;
}

.header_area .navbar .nav .nav-item:last-child {
  margin-right: 0px;
}

.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: #ffffff;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  line-height: 70px;
}

@media (min-width: 992px) {
  .header_area.white_menu .navbar .navbar-brand img {
    display: none;
  }

  .header_area.white_menu .navbar .navbar-brand img+img {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .header_area.white_menu .navbar .navbar-brand img {
    display: inline-block;
  }

  .header_area.white_menu .navbar .navbar-brand img+img {
    display: none;
  }
}

.header_area.white_menu .navbar .nav .nav-item .nav-link {
  color: #fff;
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
  display: inline-block;
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img+img {
  display: none;
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  line-height: 70px;
  color: #000000;
}

.owl-carousel.off {
  display: -ms-flexbox;
  display: flex;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  z-index: 1;
  background: url(../img/banner/home-banner.png) no-repeat top center;
  background-position: center;
  background-size: cover;
}

.home_banner_area .banner_inner {
  width: 100%;
}

.home_banner_area .banner_inner .home_right_img {
  margin-top: 197px;
}

.home_right_img{
  border: 10px solid;
  border-image-slice: 1;
  border-width: 20px;
  border-image-source:linear-gradient(to right, #4458dc 0%, #854fee 100%);
  animation: gradient 15s ease infinite;

}

@keyframes gradient {
	0% {
    border-image-source:linear-gradient(to right, #4458dc 10%, #854fee 80%);;
	}
	50% {
    border-image-source:linear-gradient(to right, #4458dc 50%, #854fee 80%);;
	}
	100% {
    border-image-source:linear-gradient(to right, #4458dc 70%, #854fee 80%);;
	}
}

.home_right_img img{
  height: 100%;
  width: 100%;
}

@media (max-width: 1480px) {
  .home_banner_area .banner_inner .home_right_img img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .home_banner_area .banner_inner .home_right_img {
  }
}

.home_banner_area .banner_inner .col-lg-7 {
  vertical-align: middle;
  align-self: center;
}

.home_banner_area .banner_inner .banner_content {
  text-align: left;
}

@media (max-width: 991px) {
  .home_banner_area .banner_inner .banner_content {
    margin-top: 30px;
  }
}

.home_banner_area .banner_inner .banner_content h3 {
  font-size: 40px;
  margin-bottom: 20px;
  position: relative;
}

.home_banner_area .banner_inner .banner_content h3:after {
  content: '';
  width: 410px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 23%;
  background: #000000;
}

@media (max-width: 575px) {
  .home_banner_area .banner_inner .banner_content h3:after {
    display: none;
  }
}

.home_banner_area .banner_inner .banner_content h1 {
  margin-top: 20px;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 25px;
}

@media (max-width: 1024px) {
  .home_banner_area .banner_inner .banner_content h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .home_banner_area .banner_inner .banner_content h1 {
    font-size: 50px;
  }
}

.home_banner_area .banner_inner .banner_content h5 {
  font-size: 24px;
  margin-bottom: 35px;
}

.home_banner_area .banner_inner .banner_content .primary_btn {
  margin-right: 20px;
}

.banner_area {
  position: relative;
  z-index: 1;
  min-height: 485px;
}

@media (max-width: 1199px) {
  .banner_area {
    min-height: 350px;
  }
}

.banner_area .banner_inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 485px;
  background: url(../img/banner/common-banner.png) no-repeat right bottom;
  background-size: 90% 85%;
  width: 98%;
  z-index: 1;
}

@media (max-width: 1199px) {
  .banner_area .banner_inner {
    min-height: 350px;
  }
}

@media (max-width: 991px) {
  .banner_area .banner_inner {
    background: #854fee;
    width: 100%;
  }
}

.banner_area .banner_inner .banner_content {
  margin-left: 50px;
  margin-top: 100px;
}

@media (max-width: 991px) {
  .banner_area .banner_inner .banner_content {
    margin-left: 0px;
    margin-top: 50px;
  }
}

.banner_area .banner_inner .banner_content h2 {
  color: #fff;
  font-size: 50px;
  font-family: "Rubik", sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 991px) {
  .banner_area .banner_inner .banner_content h2 {
    font-size: 30px;
  }
}

.banner_area .banner_inner .banner_content .page_link a {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-right: 20px;
  position: relative;
}

.banner_area .banner_inner .banner_content .page_link a:before {
  content: "-";
  position: absolute;
  right: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner_area .banner_inner .banner_content .page_link a:last-child {
  margin-right: 0px;
}

.banner_area .banner_inner .banner_content .page_link a:last-child:before {
  display: none;
}

/* End Home Banner Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Latest Blog Area css
============================================================================================ */
/* End Latest Blog Area css
============================================================================================ */
.blog_btn {
  border: 1px solid #eeeeee;
  background: #f9f9ff;
  padding: 0px 32px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #000000;
  line-height: 34px;
  display: inline-block;
}

.blog_btn:hover {
  background: #4458dc;
  border-color: #4458dc;
  color: #fff;
}

.white_bg_btn {
  background: #fff;
  display: inline-block;
  color: #000000;
  line-height: 40px;
  padding: 0px 28px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: none;
}

.white_bg_btn:hover {
  background: #4458dc;
  color: #fff;
}

/* Causes Area css
============================================================================================ */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

.causes_slider .owl-dots .owl-dot.active {
  background: #4458dc;
}

.causes_item {
  background: #fff;
}

.causes_item .causes_img {
  position: relative;
}

.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  background: #4458dc;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  background: #4458dc;
  color: #fff;
  padding: 0px 5px;
}

.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

.causes_item .causes_text h4 {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

.causes_item .causes_text h4:hover {
  color: #4458dc;
}

.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  font-weight: 300;
  margin-bottom: 0px;
}

.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid #4458dc;
  text-align: center;
  float: left;
  line-height: 50px;
  background: #4458dc;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.causes_item .causes_bottom a+a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
  color: #000000;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
.single-recent-blog-post {
  margin-bottom: 30px;
}

.single-recent-blog-post .thumb {
  overflow: hidden;
}

.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

.single-recent-blog-post .details {
  padding-top: 30px;
}

.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

.single-recent-blog-post .details .sec_h4:hover {
  color: #777777;
}

.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
  color: #000000;
}

.tags .tag_btn:before {
  background: #4458dc;
}

.tags .tag_btn+.tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
.blog_categorie_area {
  padding-bottom: 80px;
}

.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.categories_post img {
  max-width: 100%;
}

.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.8);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

.categories_post:hover .categories_details {
  background: rgba(68, 88, 220, 0.85);
}

/*============ blog_left_sidebar css ==============*/
.blog_item {
  margin-bottom: 40px;
}

.blog_info {
  padding-top: 30px;
}

.blog_info .post_tag {
  padding-bottom: 20px;
}

.blog_info .post_tag a {
  font: 300 14px/21px "Roboto", sans-serif;
  color: #000000;
}

.blog_info .post_tag a:hover {
  color: #777777;
}

.blog_info .post_tag a.active {
  color: #4458dc;
}

.blog_info .blog_meta li a {
  font: 300 14px/20px "Roboto", sans-serif;
  color: #777777;
  vertical-align: middle;
  padding-bottom: 12px;
  display: inline-block;
}

.blog_info .blog_meta li a i {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  line-height: 20px;
  vertical-align: middle;
}

.blog_info .blog_meta li a:hover {
  color: #4458dc;
}

.blog_post img {
  max-width: 100%;
}

.blog_post .primary_btn {
  line-height: 38px;
}

.blog_details {
  padding-top: 20px;
}

.blog_details h2 {
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  font-weight: 600;
  transition: all 0.3s linear;
}

.blog_details h2:hover {
  color: #4458dc;
}

.blog_details p {
  margin-bottom: 26px;
}

.view_btn {
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  color: #000000;
  font-weight: 500;
  padding: 0px 30px;
  background: #fff;
}

.blog_right_sidebar {
  border: 1px solid #eeeeee;
  background: #fafaff;
  padding: 30px;
}

.blog_right_sidebar .widget_title {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  padding: 8px 0px;
  margin-bottom: 30px;
}

.blog_right_sidebar .search_widget .input-group .form-control {
  position: relative;
  font-size: 14px;
  line-height: 29px;
  width: 100%;
  font-weight: 300;
  color: #777777;
  padding-left: 20px;
  border-radius: 45px;
  z-index: 0;
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4458dc, #854fee);
  background-image: -webkit-linear-gradient(white, white), -webkit-radial-gradient(circle at top left, #4458dc, #854fee);
  background-image: -moz-linear-gradient(white, white), -moz-radial-gradient(circle at top left, #4458dc, #854fee);
  background-image: -ms-linear-gradient(white, white), -ms-radial-gradient(circle at top left, #4458dc, #854fee);
  background-image: -o-linear-gradient(white, white), -o-radial-gradient(circle at top left, #4458dc, #854fee);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  text-indent: 20px;
}

.blog_right_sidebar .search_widget .input-group .form-control.placeholder {
  color: #777777;
  text-transform: uppercase;
  font-size: 12px;
}

.blog_right_sidebar .search_widget .input-group .form-control:-moz-placeholder {
  color: #777777;
  text-transform: uppercase;
  font-size: 12px;
}

.blog_right_sidebar .search_widget .input-group .form-control::-moz-placeholder {
  color: #777777;
  text-transform: uppercase;
  font-size: 12px;
}

.blog_right_sidebar .search_widget .input-group .form-control::-webkit-input-placeholder {
  color: #777777;
  text-transform: uppercase;
  font-size: 12px;
}

.blog_right_sidebar .search_widget .input-group .form-control:focus {
  box-shadow: none;
}

.blog_right_sidebar .search_widget .input-group .btn-default {
  position: absolute;
  right: 20px;
  background: transparent;
  border: 0px;
  box-shadow: none;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  padding: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.blog_right_sidebar .author_widget {
  text-align: center;
}

.blog_right_sidebar .author_widget h4 {
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 5px;
  margin-top: 30px;
}

.blog_right_sidebar .author_widget p {
  margin-bottom: 0px;
}

.blog_right_sidebar .author_widget .social_icon {
  padding: 7px 0px 15px;
}

.blog_right_sidebar .author_widget .social_icon a {
  font-size: 14px;
  color: #000000;
  transition: all 0.2s linear;
}

.blog_right_sidebar .author_widget .social_icon a+a {
  margin-left: 20px;
}

.blog_right_sidebar .author_widget .social_icon a:hover {
  color: #4458dc;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 4px;
  transition: all 0.3s linear;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
  color: #4458dc;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0px;
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
  margin-top: 20px;
}

.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 2px dotted #eee;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #777;
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
  padding-top: 15px;
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
  color: #4458dc;
}

.blog_right_sidebar .post_category_widget .cat-list li:hover {
  border-color: #4458dc;
}

.blog_right_sidebar .newsletter_widget {
  text-align: center;
}

.blog_right_sidebar .newsletter_widget .form-group {
  margin-bottom: 8px;
}

.blog_right_sidebar .newsletter_widget .input-group-prepend {
  margin-right: -1px;
}

.blog_right_sidebar .newsletter_widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
  font-size: 12px;
  line-height: 36px;
  padding: 0px 0px 0px 15px;
  border: 1px solid #eeeeee;
  border-right: 0px;
}

.blog_right_sidebar .newsletter_widget .form-control {
  font-size: 12px;
  line-height: 24px;
  color: #cccccc;
  border: 1px solid #eeeeee;
  border-left: 0px;
  border-radius: 0px;
}

.blog_right_sidebar .newsletter_widget .form-control.placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control:-moz-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
  outline: none;
  box-shadow: none;
}

.blog_right_sidebar .newsletter_widget .bbtns {
  color: #fff;
  font-size: 12px;
  line-height: 38px;
  display: inline-block;
  font-weight: 500;
  padding: 0px 24px 0px 24px;
  border-radius: 0;
}

.blog_right_sidebar .newsletter_widget .text-bottom {
  font-size: 12px;
}

.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 0px 13px;
  margin-bottom: 8px;
  transition: all 0.3s ease 0s;
  color: #000000;
  font-size: 12px;
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  color: #fff;
}

.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

.blog-pagination {
  padding-top: 25px;
  padding-bottom: 95px;
}

.blog-pagination .page-link {
  border-radius: 0;
}

.blog-pagination .page-item {
  border: none;
}

.page-link {
  background: transparent;
  font-weight: 400;
}

.blog-pagination .page-item.active .page-link {
  border-color: transparent;
  color: #fff;
}

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8a8a8a;
  border: none;
}

.blog-pagination .page-link .lnr {
  font-weight: 600;
}

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  border-color: #eee;
}

/*============ Start Blog Single Styles  =============*/
.single-post-area .social-links {
  padding-top: 10px;
}

.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

.single-post-area .social-links li a:hover {
  color: #4458dc;
}

.single-post-area .blog_details {
  padding-top: 26px;
}

.single-post-area .blog_details p {
  margin-bottom: 10px;
}

.single-post-area .quotes {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 24px 35px 24px 30px;
  background-color: white;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
  font-size: 14px;
  line-height: 24px;
  color: #777;
  font-style: italic;
}

.single-post-area .arrow {
  position: absolute;
}

.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
  margin-top: 60px;
}

.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.single-post-area .navigation-area .nav-left {
  text-align: left;
}

.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

.single-post-area .navigation-area .nav-right {
  text-align: right;
}

.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 30px;
  margin-top: 50px;
}

@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}

.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
  color: #000000;
  font-size: 18px;
}

.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

.comments-area a {
  color: #000000;
}

.comments-area .comment-list {
  padding-bottom: 48px;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }

  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }

  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .date {
  font-size: 13px;
  color: #cccccc;
  margin-bottom: 13px;
}

.comments-area .comment {
  color: #777777;
  margin-bottom: 0px;
}

.comments-area .btn-reply {
  background-color: #fff;
  color: #000000;
  border: 1px solid #eee;
  padding: 2px 18px;
  font-size: 12px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.comments-area .btn-reply:hover {
  border: transparent;
  color: #fff;
}

.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 47px 30px 43px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}

.comment-form .form-control {
  padding: 8px 20px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 14px;
  color: #777777;
  border: 1px solid transparent;
}

.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}

.comment-form textarea.form-control {
  height: 140px;
  resize: none;
}

.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== contact_area css ================*/
.mapBox {
  height: 420px;
  margin-top: 80px;
}

.contact_info .info_item {
  position: relative;
  /* padding-left: 45px; */
}

.contact_info .info_item i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.contact_info .info_item h6 {
  font-size: 16px;
  line-height: 24px;
  color: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000000;
}

.contact_info .info_item h6 a {
  color: #000000;
}

.contact_info .info_item p {
  font-size: 14px;
  line-height: 24px;
  padding: 2px 0px;
}

.contact_form .form-group {
  margin-bottom: 10px;
}

.contact_form .form-group .form-control {
  font-size: 13px;
  line-height: 26px;
  color: #999;
  border: 1px solid #eeeeee;
  font-family: "Roboto", sans-serif;
  border-radius: 0px;
  padding-left: 20px;
}

.contact_form .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}

.contact_form .form-group .form-control.placeholder {
  color: #999;
}

.contact_form .form-group .form-control:-moz-placeholder {
  color: #999;
}

.contact_form .form-group .form-control::-moz-placeholder {
  color: #999;
}

.contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #999;
}

.contact_form .form-group textarea {
  resize: none;
}

.contact_form .form-group textarea.form-control {
  height: 134px;
}

.contact_form .primary_btn {
  margin-top: 20px;
}

/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  color: #4458dc;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* End Contact Success and error Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== Elements Area css ================*/
.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.sample-text-area {
  padding: 100px 0px;
}

.sample-text-area .title_color {
  margin-bottom: 30px;
}

.sample-text-area p {
  line-height: 26px;
}

.sample-text-area p b {
  font-weight: bold;
  color: #4458dc;
}

.sample-text-area p i {
  color: #4458dc;
  font-style: italic;
}

.sample-text-area p sup {
  color: #4458dc;
  font-style: italic;
}

.sample-text-area p sub {
  color: #4458dc;
  font-style: italic;
}

.sample-text-area p del {
  color: #4458dc;
}

.sample-text-area p u {
  color: #4458dc;
}

/*============== End Elements Area css ================*/
/*==============Elements Button Area css ================*/
.elements_button .title_color {
  margin-bottom: 30px;
  color: #000000;
}

.title_color {
  color: #000000;
}

.button-group-area {
  margin-top: 15px;
}

.button-group-area:nth-child(odd) {
  margin-top: 40px;
}

.button-group-area:first-child {
  margin-top: 0px;
}

.button-group-area .theme_btn {
  margin-right: 10px;
}

.button-group-area .link {
  text-decoration: underline;
  color: #000000;
  background: transparent;
}

.button-group-area .link:hover {
  color: #fff;
}

.button-group-area .disable {
  background: transparent;
  color: #007bff;
  cursor: not-allowed;
}

.button-group-area .disable:before {
  display: none;
}

.primary {
  background: #52c5fd;
}

.primary:before {
  background: #2faae6;
}

.success {
  background: #4cd3e3;
}

.success:before {
  background: #2ebccd;
}

.info {
  background: #38a4ff;
}

.info:before {
  background: #298cdf;
}

.warning {
  background: #f4e700;
}

.warning:before {
  background: #e1d608;
}

.danger {
  background: #f54940;
}

.danger:before {
  background: #e13b33;
}

.primary-border {
  background: transparent;
  border: 1px solid #52c5fd;
  color: #52c5fd;
}

.primary-border:before {
  background: #52c5fd;
}

.success-border {
  background: transparent;
  border: 1px solid #4cd3e3;
  color: #4cd3e3;
}

.success-border:before {
  background: #4cd3e3;
}

.info-border {
  background: transparent;
  border: 1px solid #38a4ff;
  color: #38a4ff;
}

.info-border:before {
  background: #38a4ff;
}

.warning-border {
  background: #fff;
  border: 1px solid #f4e700;
  color: #f4e700;
}

.warning-border:before {
  background: #f4e700;
}

.danger-border {
  background: transparent;
  border: 1px solid #f54940;
  color: #f54940;
}

.danger-border:before {
  background: #f54940;
}

.link-border {
  background: transparent;
  border: 1px solid #4458dc;
  color: #4458dc;
}

.link-border:before {
  background: #4458dc;
}

.radius {
  border-radius: 3px;
}

.circle {
  border-radius: 20px;
}

.arrow span {
  padding-left: 5px;
}

.e-large {
  line-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.large {
  line-height: 45px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.medium {
  line-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.small {
  line-height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.general {
  line-height: 38px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/*==============End Elements Button Area css ================*/
/* =================================== */
/*  Elements Page Styles
/* =================================== */
/*---------- Start Elements Page -------------*/
.generic-banner {
  margin-top: 60px;
  text-align: center;
}

.generic-banner .height {
  height: 600px;
}

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}

.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}

.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}

.generic-content h1 {
  font-weight: 600;
}

.about-generic-area {
  background: #fff;
}

.about-generic-area p {
  margin-bottom: 20px;
}

.white-bg {
  background: #fff;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.switch-wrap {
  margin-bottom: 10px;
}

.switch-wrap p {
  margin: 0;
}

/*---------- End Elements Page -------------*/
.sample-text-area {
  padding: 100px 0 70px 0;
}

.sample-text {
  margin-bottom: 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

.typo-list {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}

b, sup, sub, u, del {
  color: #854fee;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  color: #777777;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.circle {
  border-radius: 20px;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #f8b600;
}

@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}

.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: flex;
}

.progress-table .table-head .serial, .progress-table .table-head .country, .progress-table .table-head .visit, .progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

.progress-table .table-row .serial, .progress-table .table-row .country, .progress-table .table-row .visit, .progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-gallery-image:hover {
  opacity: .8;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f8b600;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #f8b600;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.tooltip {
  --bs-tooltip-bg: #854fee !important;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input+label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f8b600;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked+label {
  left: 19px;
}

.single-element-widget {
  margin-bottom: 30px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input+label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked+label:after {
  left: 19px;
}

.primary-switch input:checked+label:before {
  background: #f8b600;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input+label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked+label:after {
  left: 19px;
}

.confirm-switch input:checked+label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.single-defination h4 {
  color: #000000;
}

.primary-checkbox input:checked+label {
  background: url(../img/elements/primary-check.png) no-repeat center center/cover;
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked+label {
  background: url(../img/elements/success-check.png) no-repeat center center/cover;
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked+label {
  background: url(../img/elements/disabled-check.png) no-repeat center center/cover;
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked+label {
  background: url(../img/elements/primary-radio.png) no-repeat center center/cover;
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked+label {
  background: url(../img/elements/success-radio.png) no-repeat center center/cover;
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.unordered-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ordered-list {
  list-style: none;
  padding: 0px;
}

.disabled-radio input:checked+label {
  background: url(../img/elements/disabled-radio.png) no-repeat center center/cover;
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}

.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.mt-10 {
  margin-top: 10px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Start About Us Area css
============================================================================================ */
.about_area {
  position: relative;
}

.about_area .main_title {
  margin-bottom: 0px;
  text-align: left;
}

.about_area .main_title p {
  margin-bottom: 20px;
}

.about_area .primary_btn {
  margin-top: 20px;
}

.about_area .about_img {
  position: relative;
}

@media (max-width: 1480px) {
  .about_area .about_img {
    right: 0;
  }

  .about_area .about_img img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .about_area .about_img {
    display: none;
  }
}




.feature_item {
  padding: 45px 25px;
  text-align: center;
  background: #f9f9fd;
  cursor: pointer;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.feature_item img {
  margin-bottom: 35px;
  height: 70px;
  width: auto !important;
}

.feature_item h4 {
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.feature_item p {
  margin-bottom: 0px;
}

.feature_item:hover {
  box-shadow: 0px 15px 30px rgba(77, 87, 222, 0.3);
  background: #fff;
}

/* End Features Area css
============================================================================================ */
/* Start Brand Area css
============================================================================================ */
.single-brand-item {
  height: 100px;
  cursor: pointer;
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  box-shadow: none;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 767px) {
  .single-brand-item {
    height: 100px;
  }
}

@media (max-width: 575px) {
  .single-brand-item {
    margin-bottom: 30px;
  }
}

.single-brand-item .d-table-cell {
  vertical-align: middle;
}

.single-brand-item img {
  max-width: 80px;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 767px) {
  .single-brand-item img {
    max-width: 100px;
  }
}

.single-brand-item:hover {
  border: 1px solid transparent;
  box-shadow: 0px 8px 30px rgba(118, 85, 225, 0.15);
}

/* .single-brand-item:hover img {
  filter: brightness(0%);
  -o-filter: brightness(0%);
  -ms-filter: brightness(0%);
  -moz-filter: brightness(0%);
  -webkit-filter: brightness(0%);
} */

.client-info {
  padding: 25px 30px;
  background: #faf8ff;
  text-align: center;
  position: relative;
  margin-top: 25px;
}

@media only screen and (max-width: 991px) {
  .client-info {
    margin-top: 50px;
  }
}

@media (max-width: 575px) {
  .client-info {
    padding-top: 50px;
  }
}

.client-info:before {
  content: '';
  position: absolute;
  top: -25px;
  left: -50px;
  background: url(../img/client-info-bg.png) no-repeat;
  height: 175px;
  width: 175px;
}

@media (max-width: 575px) {
  .client-info:before {
    background: none;
  }
}

@media (max-width: 575px) {
  .client-info .d-flex {
    -ms-flex-align: center;
    align-items: center;
  }
}

.client-info .lage {
  color: #854fee;
  font-family: "Rubik", sans-serif;
  font-size: 100px;
  font-weight: 700;
  line-height: 85px;
  position: relative;
  z-index: 9;
}

.client-info .smll {
  text-align: left;
  color: #000000;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  margin-left: 20px;
}

.call-now {
  padding-top: 50px;
  text-align: left;
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .call-now {
    padding-top: 0px;
  }
}

@media (max-width: 480px) {
  .call-now h3 {
    font-size: 17px;
  }
}

.call-now p {
  color: #854fee;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.call-now span {
  font-size: 60px;
  font-weight: 700;
  color: #000000;
}

/* End Brand Area css
============================================================================================ */
/* Start Portfolio Area css
============================================================================================ */

.portfolio_area .filters ul {
  padding-left: 0;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .portfolio_area .filters ul {
    margin-bottom: 20px;
  }
}

.portfolio_area .filters ul button {
  display: inline-block;
  background-color: transparent;
  border: none;
  margin-right: 50px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #222222;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.portfolio_area .filters ul li:hover, .portfolio_area .filters ul button.active {
  color: #854fee;
  font-weight: bold;
}

@media (max-width: 767px) {
  .portfolio_area .filters ul li {
    margin-right: 20px;
  }
}

.portfolio_box {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .portfolio_box {
    margin-bottom: 35px;
  }
}

.portfolio_box .short_info {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .portfolio_box .short_info {
    margin-top: 20px;
  }
}

.portfolio_box .short_info h4 {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0px;
}

.portfolio_box .short_info h4 a {
  color: #000000;
}

.single_portfolio {
  position: relative;
}

.single_portfolio .overlay {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single_portfolio .icon {
  position: absolute;
  top: 25%;
  left: 25%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single_portfolio .icon .btn-sm {
  line-height: 25px !important;
  width: 100%;
}

.single_portfolio .icon .btn-sm span {
  padding: 0 14px !important;

}

.single_portfolio:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}

.single_portfolio:hover .icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
  left: 50%;
}

.portfolio_details_inner .row {
  margin-bottom: 65px;
}

@media (max-width: 480px) {
  .portfolio_details_inner .row {
    margin-bottom: 30px;
  }
}

.portfolio_details_inner p {
  margin-bottom: 30px;
  font-size: 14px;
}

.portfolio_details_inner p:last-child {
  margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text {
  margin-top: 50px;
}

.portfolio_details_inner .portfolio_right_text h4 {
  font-size: 20px;
  margin-bottom: 18px;
}

.portfolio_details_inner .portfolio_right_text p {
  margin-bottom: 25px;
  font-size: 14px;
}

.portfolio_details_inner .portfolio_right_text .list li {
  margin-bottom: 7px;
  font-weight: normal;
  font-size: 14px;
}

.portfolio_details_inner .portfolio_right_text .list li span {
  font-family: "Rubik", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  width: 97px;
  display: inline-block;
}

.portfolio_details_inner .portfolio_right_text .list li:last-child {
  margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text .list li i {
  color: #f1cd09;
}

/* End Portfolio Area css
============================================================================================ */
/* Start Testimonial Area css
============================================================================================ */
.testimonial_area .owl-carousel .owl-dots {
  display: block !important;
  text-align: center;
}

.testi_item {
  position: relative;
  overflow: hidden;
}

.testi_item .marker {
  padding: 0px 10px;
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%);
  position: absolute;
  right: 0px;
  top: 11px;
  width: auto;
}

.testi_item .marker p {
  font-size: 12px;
}

.testimonial_area .owl-carousel .owl-dots .owl-dot {
  height: 10px;
  width: 10px;
  background: #d8d8d8;
  border-radius: 17px;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.testimonial_area .owl-carousel .owl-dots .owl-dot.active {
  height: 12px;
  width: 12px;
  background: #854fee;
}

@media (max-width: 991px) {
  .testimonial_area .owl-carousel .owl-dots {
    display: none !important;
  }
}

.testimonial_area .owl-carousel .owl-item img {
  width: auto !important;
}

.testi_item {
  padding: 20px 20px;
  margin-bottom: 200px;
  margin-left: 10px;
  margin: 0px 10px 30px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.testi_slider .slick-track {
  padding: 40px 0px;
  display: flex;
  gap: 15px;
}

.testi_slider .slick-track .slick-slide {
  height: inherit;
  box-shadow: 0 .5rem .5rem rgba(0,0,0,.10)!important;
}

.testi_item .client-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.testi_item .flag {
  height: auto;
  width: 25px;
}

.testi_item .gold_star {
  color: gold;
}

@media (max-width: 991px) {
  .testi_item {
    margin-bottom: 0px;
  }
}

@media (max-width: 575px) {
  .testi_item {
    padding: 30px 20px;
  }
}

.testi_item img {
  border-radius: 5px;
}

.testi_item .col-lg-8 {
  vertical-align: middle;
  align-self: center;
}

@media (max-width: 991px) {
  .testi_item .testi_text {
    margin-top: 50px;
  }
}

.testi_item .testi_text h4 {
  font-size: 20px;
  text-transform: uppercase;
}

.testi_item .testi_text p {
  margin-bottom: 0px;
}

/* .testi_item:hover {
  box-shadow: 0px 10px 20px 0px rgba(118, 85, 225, 0.1);
} */

/* End Testimonial Area css
============================================================================================ */
/* Start Newsletter Area css
============================================================================================ */
.newsletter_area {
  color: #ffffff;
}

.subscription_box {
  background: url(../img/subscribe-bg.png) no-repeat center;
  padding: 150px 0px;
}

@media (max-width: 767px) {
  .subscription_box {
    padding: 50px 20px;
  }
}

.subscription_box h2 {
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .subscription_box h2 {
    font-size: 24px;
  }
}

.subscription_box p {
  margin-bottom: 0;
}

.subscription_box .subscription input {
  min-width: 380px;
  max-width: 380px;
  line-height: 48px;
  padding: 0 25px;
  border: 1px solid #ffffff;
  background: transparent;
  font-weight: 400;
  border-radius: 5px;
  color: #ffffff;
}

.subscription_box .subscription input:focus {
  outline: none;
}

@media (max-width: 420px) {
  .subscription_box .subscription input {
    min-width: 250px;
  }
}

.subscription_box .subscription input.placeholder {
  color: #ffffff;
  font-weight: 400;
}

.subscription_box .subscription input:-moz-placeholder {
  color: #ffffff;
  font-weight: 400;
}

.subscription_box .subscription input::-moz-placeholder {
  color: #ffffff;
  font-weight: 400;
}

.subscription_box .subscription input::-webkit-input-placeholder {
  color: #ffffff;
  font-weight: 400;
}

.subscription_box .primary-btn {
  margin-left: 10px;
  background: #ffffff;
  text-transform: uppercase;
  color: #222222;
  border-radius: 5px;
  line-height: 48px;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  padding: 0px 38px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .subscription_box .primary-btn {
    margin-top: 20px;
  }
}

.subscription_box .subcribe-form {
  margin-top: 45px;
}

.subscription .info {
  color: #fff;
  width: 100%;
  font-size: 12px;
  background: transparent;
}

.subscription .info.valid {
  color: green;
}

.subscription .info.error {
  color: red;
}

/* End Newsletter Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
@media (max-width: 1619px) {

  /* Main Menu Area css
  ============================================================================================ */
  .header_area .navbar .search {
    margin-left: 40px;
  }

  /* End Main Menu Area css
  ============================================================================================ */
}

@media (max-width: 1199px) {

  /* Main Menu Area css
  ============================================================================================ */
  .header_area .navbar .nav .nav-item {
    margin-right: 28px;
  }

  /* End Main Menu Area css
  ============================================================================================ */
  /* Home Banner Area css
  ============================================================================================ */
  .home_banner_area .banner_inner {
    padding: 100px 0px;
  }

  /* End Home Banner Area css
  ============================================================================================ */
  .header_area .navbar .primary_btn {
    margin-left: 40px;
  }

  .home_banner_area .banner_inner .banner_content h2 br {
    display: none;
    font-size: 36px;
  }

  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 36px;
    line-height: 45px;
  }
}

@media (max-width: 991px) {

  /* Main Menu Area css
  ============================================================================================ */
  .navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 23px;
  }

  .header_area .navbar {
    background: #000;
  }

  .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1;
  }

  .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggler[aria-expanded="true"] span:first-child {
    transform: rotate(-45deg);
    position: relative;
    top: 7.5px;
  }

  .navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(45deg);
    bottom: 6px;
    position: relative;
  }

  .navbar-toggler span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer;
  }

  .navbar .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav {
    padding: 0px 0px;
  }

  .header_area+section,
  .header_area+row,
  .header_area+div {
    margin-top: 117px;
  }

  .header_top .nav {
    padding: 0px;
  }

  .header_area .navbar .nav .nav-item .nav-link {
    line-height: 40px;
    margin-right: 0px;
    display: block;
    border-bottom: 1px solid #ededed33;
    border-radius: 0px;
    color: #fff;
  }

  .header_area .navbar .search {
    margin-left: 0px;
  }

  .header_area .navbar-collapse {
    max-height: 340px;
    overflow-y: scroll;
  }

  .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    padding: 0px 15px;
  }

  .header_area .navbar .nav .nav-item {
    margin-right: 0px;
  }

  .header_area+section,
  .header_area+row,
  .header_area+div {
    margin-top: 0px;
  }

  .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 40px;
    color: #fff;
  }

  .header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 40px;
    color: #fff;
  }

  .header_area.white_menu .navbar .nav .nav-item .nav-link {
    color: #222;
  }

  /* End Main Menu Area css
  ============================================================================================ */
  /* Start Home banner Area css
  ============================================================================================ */
  .home_banner_area {
    margin-top: 0;
  }



  /* Footer Area css
  ============================================================================================ */
  .footer_area {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    background-image: none !important;
    background-color: #fcf8ff;
  }

  /* Blog page Area css
  ============================================================================================ */
  .categories_post img {
    width: 100%;
  }

  .categories_post {
    max-width: 360px;
    margin: 0 auto;
  }

  .blog_categorie_area .col-lg-4 {
    margin-top: 30px;
  }

  .blog_area {
    padding-bottom: 80px;
  }

  .single-post-area .blog_right_sidebar {
    margin-top: 30px;
  }

  /* End Blog page Area css
  ============================================================================================ */
  /* Contact Page Area css
  ============================================================================================ */
  .contact_info {
    margin-bottom: 50px;
  }

  /* End Contact page Area css
  ============================================================================================ */
  .home_banner_area .donation_inner {
    margin-bottom: -30px;
  }

  .home_banner_area .dontation_item {
    max-width: 350px;
    margin: auto;
  }

  /* Footer Area css
  ============================================================================================ */
  .footer_area .col-sm-6 {
    margin-bottom: 30px;
  }

  .footer_area .footer_inner {
    margin-bottom: -30px;
  }

  .news_widget {
    padding-left: 0px;
  }

  /* End End Footer Area css
  ============================================================================================ */
  .home_banner_area .banner_inner .home_left_img {
    display: none;
  }

  .header_area .navbar .primary_btn {
    display: none;
  }

  .left_side_text {
    margin-bottom: 50px;
  }

  .price_item {
    max-width: 360px;
    margin: 0px auto 30px;
  }

  .price_inner {
    margin-bottom: -30px;
  }
}

@media (max-width: 767px) {
  .home_banner_area {
    min-height: 300px;
  }

  .home_banner_area .banner_inner {
    min-height: 300px;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .home_banner_area .banner_inner .banner_content {
    margin-top: 0px;
  }

  .home_banner_area .banner_inner .banner_content .primary_btn {
    display: none;
  }

  /* Blog Page Area css
  ============================================================================================ */
  .blog_info.text-right {
    text-align: left !important;
    margin-bottom: 10px;
  }

  /* End Blog Page Area css
  ============================================================================================ */
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 30px;
  }

  .home_banner_area .banner_inner .banner_content p br {
    display: none;
  }

  .home_banner_area .banner_inner .banner_content h3 span {
    line-height: 45px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  /* Footer Area css
  ============================================================================================ */
  .footer_area .f_title {
    margin-bottom: 20px;
  }

  .footer-bottom {
    text-align: center;
  }

  .footer-bottom .footer-social {
    text-align: center;
    margin-top: 15px;
  }

  /* End End Footer Area css
  ============================================================================================ */
  .made_life_inner .nav.nav-tabs li {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }

  .made_life_inner .nav.nav-tabs {
    margin-bottom: -15px;
  }

  .made_life_area.made_white .left_side_text {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {

  .header_area+section,
  .header_area+row,
  .header_area+div {
    margin-top: 0px;
  }

  /* Home Banner Area css
  ============================================================================================ */
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .home_banner_area {
    min-height: 450px;
  }

  .home_banner_area .banner_inner {
    min-height: 450px;
  }

  .home_banner_area .banner_inner .banner_content img {
    display: none;
  }

  .home_banner_area .banner_inner .banner_content h5 {
    margin-top: 0px;
  }

  /* End Home Banner Area css
  ============================================================================================ */
  .section_gap {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .main_title h2 {
    font-size: 25px;
  }

  /*Recent Update Area css
  ============================================================================================ */
  /* Elements Area css
  ============================================================================================ */
  .sample-text-area {
    padding: 70px 0 70px 0;
  }

  .generic-blockquote {
    padding: 30px 15px 30px 30px;
  }

  /* End Elements Area css
  ============================================================================================ */
  /* Blog Page Area css
  ============================================================================================ */
  .blog_details h2 {
    font-size: 20px;
    line-height: 30px;
  }

  /* End Blog Page Area css
  ============================================================================================ */
  /* Footer Area css
  ============================================================================================ */
  .footer-area {
    padding: 70px 0px;
  }

  .news_widget {
    padding-left: 0 !important;
  }

  /* End End Footer Area css
  ============================================================================================ */
  .pad_top {
    padding-top: 70px;
  }

  .pad_btm {
    padding-bottom: 70px;
  }
}

@media (max-width: 480px) {

  /* Main Menu Area css
  ============================================================================================ */
  .header_area .navbar-collapse {
    max-height: 250px;
  }

  /* End Main Menu Area css
  ============================================================================================ */
  /* Home Banner Area css
  ============================================================================================ */
  .home_banner_area .banner_inner .banner_content {
    padding: 30px 15px;
    margin-top: 0px;
  }

  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 24px;
  }

  /* End Home Banner Area css
  ============================================================================================ */
  .banner_area .banner_inner .banner_content h2 {
    font-size: 32px;
  }

  /* Blog Page Area css
  ============================================================================================ */
  .comments-area .thumb {
    margin-right: 10px;
  }

  /* End Blog Page Area css
  ============================================================================================ */
  .testi_item .media .d-flex {
    padding-right: 0px;
  }

  .testi_item .media .d-flex img {
    width: auto;
    margin-bottom: 15px;
  }

  .testi_item .media {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

  /* Start Brand Carousel Area css
  ============================================================================================ */
  .brand-carousel .single-brand-item {
    height: 100px;
    width: 140px;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Footer Area css
============================================================================================ */
.blog_version .footer_area {
  background: #f9f9fd;
}

.blog_version .footer_area:before {
  content: none;
}

.footer_area {
  padding: 120px 0;
  position: relative;
}

.footer_area:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #f9f9fd;
  z-index: -1;
}

@media (max-width: 1024px) {
  .footer_area {
    padding: 60px 0;
  }
}

.footer_area .footer_logo {
  text-align: center;
}

.footer_area .footer_logo h4 {
  font-size: 20px;
  color: #000000;
  margin-top: 35px;
  text-transform: uppercase;
}

.footer_area .footer_social {
  text-align: center;
  margin: 25px 0px 30px;
}

.footer_area .footer_social a {
  padding: 0 20px;
}

.footer_area .footer_social a i {
  color: #788489;
  font-size: 22px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media (max-width: 576px) {
  .footer_area .footer_social a i {
    font-size: 25px;
  }
}

.footer_area .footer_social a i:hover {
  color: #854fee;
}

.footer_area .footer_bottom {
  text-align: center;
}

.footer_area .footer_bottom p {
  margin-bottom: 0;
}

.footer_area .footer_bottom a {
  color: #854fee;
}

/* End Footer Area css
============================================================================================ */
/*---------------------------------------------------- */


/* Custom css here  */
.gap_90 {
  height: 90px;
}


.intro{
  font-size: 18px;
  line-height: 35px;
  letter-spacing: normal;
}

.bg-primary{
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%) !important;
}

.slick-dots li.slick-active button:before{
  color: #854fee;
  font-size: 12px;
}
.slick-dots li button:before{
  font-size: 12px;

}




@media screen and (max-width:1400px) {
  .home_banner_area .banner_inner .banner_content h3{
    font-size: 28px;
  }
  .home_banner_area .banner_inner .banner_content h1{
    font-size: 45px;
  }

  .home_banner_area .banner_inner .home_right_img {
    margin-top: 100px;
}

}



@media screen and (max-width:1024px) {
  .gap_90 {
    height: 50px;
  }
}


@media screen and (max-width:992px) {
  .gap_90 {
    height: 45px;
  }
  .brand_area {
    margin-top: 50px;
  }
}


@media screen and (max-width:768px) {
  .gap_90 {
    height: 30px;
  }

  .home_right_img {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(to right, #4458dc 0%, #854fee 100%);
    animation: gradient 15s ease infinite;
}
.intro {
  font-size: 16px;
  line-height: 31px;
  letter-spacing: normal;
}

.primary-btn{
  font-size: 12px !important;
  line-height: 35px !important;
}
}


@media screen and (max-width:425px) {
  .home_banner_area .banner_inner .home_right_img {
    margin-top: 0px !important;
}
.home_banner_area .banner_inner .banner_content h1 {
  font-size: 26px !important;
}
.home_banner_area .banner_inner .banner_content h1 {
  font-size: 60px;
  line-height: 22px !important;
}
.home_banner_area .banner_inner .banner_content h3 {
  font-size: 18px;
}
}
.active>.page-link, .page-link.active{
background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%) !important
}
#basic-addon1{
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%) !important;
  color: white;
}
.search{
  max-width: 500px;
  width: 100%;
  margin: auto;
  min-height: 50px;
  margin-bottom: 50px;
}
input:focus{
  box-shadow: none !important;
}
.page-link:focus{
  box-shadow: none !important;
}
.page-link {
 transition: .5s;
}
.page-link:hover{
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%) !important;
  color: white;
}

@media screen and (max-width:375px) {}
